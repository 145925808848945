import {H5, HTMLTable} from "@blueprintjs/core";
import {Money} from "../basic/Money";
import {TransactionTable} from "./TransactionTable";
import React from "react";
import BigNumber from "bignumber.js";
import {DateTime} from "../basic/DateTime";

export function DepotEntryDetail({depotEntry, performanceStartDate}) {
    return <>
        <H5 style={{margin: "0 0 10px 0"}}>Performance seit <DateTime value={performanceStartDate} dateOnly/></H5>
        <HTMLTable compact striped>
            <thead>
            <tr>
                <th>Währung</th>
                <th>Menge</th>
                <th>Cash</th>
                <th>Dividende</th>
                <th>Gebühren</th>
                <th>Total</th>
            </tr>
            </thead>
            <tbody>
            {depotEntry.currencies && Object.entries(depotEntry.currencies).map(cur => <tr key={"CASH" + cur[0]}>
                <td>{cur[0]}</td>
                <td style={alignRight}>
                    <Money value={new BigNumber(cur[1].quantity)}/>
                </td>
                <td style={alignRight}>
                    <Money value={new BigNumber(cur[1].cash)}/>
                </td>
                <td style={alignRight}>
                    <Money value={new BigNumber(cur[1].dividends)}/>
                </td>
                <td style={alignRight}>
                    <Money value={new BigNumber(cur[1].fees)}/>
                </td>

                <td style={alignRight}>
                    <Money value={new BigNumber(cur[1].total)}/>
                </td>
            </tr>)}
            </tbody>
        </HTMLTable>
        <HTMLTable compact striped>
            <thead>
            <tr>
                <th>Gewinn</th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td>Assets {depotEntry.currency}</td>
                <td style={alignRight}><Money value={depotEntry.totalvalue}/></td>
            </tr>
            {depotEntry.currency !== "CHF" &&
            <tr>
                <td>Gewinn {depotEntry.currency}</td>
                <td style={alignRight}><Money value={depotEntry.totalGain} colored/></td>
            </tr>}
            <tr style={{fontWeight: "600"}}>
                <td>Gewinn CHF</td>
                <td style={{...alignRight,}}>
                    <Money value={depotEntry.totalGainChf} colored/>
                </td>
            </tr>
            </tbody>
        </HTMLTable>
        <H5 style={{margin: "15px 0 5px 0"}}>Transaktionen</H5>
        <TransactionTable symbol={depotEntry.symbol}/>
    </>;
}

const alignRight = {
    textAlign: "right"
}