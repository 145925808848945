import React from "react";
import {Button, ButtonGroup, Card, FormGroup, H3, InputGroup, Switch} from "@blueprintjs/core";
import {useForm} from "react-hook-form";
import {feedbackIntegration} from "@sentry/react";
import {Spinner} from "./basic/Spinner";
import {Grid} from "./basic/Grid";
import {useGlobalContext} from "./GlobalContext";

export function SettingsPage() {
    let {data, loading, reload} = useGlobalContext().userSettings;

    let saveSettings = data => fetch("/api/user/settings",
        {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data)
        }).finally(() => {
        return reload();
    });

    return <div style={{maxWidth: "400px"}}>
        <Card>
            <H3>Einstellungen</H3>
            <FormGroup label="Google Account">
                {data.google_email}
            </FormGroup>
            <Spinner loading={loading}>
                <Form
                    initialState={data}
                    saveSettings={saveSettings}
                />
            </Spinner>
        </Card>
        <Card>
            <SentryFeedbackButton/>
        </Card>
    </div>
}

function Form({initialState, saveSettings}) {
    let methods = useForm({defaultValues: initialState});
    let register = methods.register;

    let performanceStartDate = register("performance_start_date");
    let emailNotificationsEnabled = register("email_notifications_enabled");

    return <form onSubmit={methods.handleSubmit(saveSettings)}>
        <Grid gap="5px">
            <FormGroup label="Berechne Gewinn ab">
                <InputGroup
                    type="date"
                    autoFocus
                    inputRef={performanceStartDate.ref}
                    name={performanceStartDate.name}
                    onChange={performanceStartDate.onChange}
                    onBlur={performanceStartDate.onBlur}
                />
            </FormGroup>
            <Switch
                label="Email Notifikationen"
                inputRef={emailNotificationsEnabled.ref}
                name={emailNotificationsEnabled.name}
                onChange={emailNotificationsEnabled.onChange}
                onBlur={emailNotificationsEnabled.onBlur}
            />
            <ButtonGroup>
                <Button text="Speichern" intent="primary" type="submit" disabled={!methods.formState.isDirty}/>
            </ButtonGroup>
        </Grid>

    </form>;
}

const feedback = feedbackIntegration({
    // Disable injecting the default widget
    autoInject: false,
    showEmail: false,
    showName: false,
    useSentryUser: {
        name: "id"
    }
});

function SentryFeedbackButton() {
    // Don't render custom feedback button if Feedback integration isn't installed
    if (!feedback) {
        return null;
    }

    return (
        <Button intent="warning" onClick={() => feedback.openDialog()} icon="issue-new">
            Feedback / Problem melden
        </Button>
    );
}