import React, {useState} from "react";
import {Button, Checkbox, Classes, Popover} from "@blueprintjs/core";

export function ColumnSelector({gridApi}) {
    const [, setForceUpdate] = useState(0);

    return <Popover
        popoverClassName={Classes.POPOVER_CONTENT_SIZING}
        content={
            <div>
                {gridApi?.getColumnDefs()?.map(colDef => {
                    return <Checkbox
                        key={colDef.colId}
                        checked={!colDef.hide}
                        onChange={e => {
                            let checked = e.currentTarget.checked;
                            gridApi.setColumnsVisible([colDef.colId], checked);
                            setForceUpdate(prev => prev + 1);
                        }}
                    >
                        {colDef.headerName}
                    </Checkbox>
                })}
            </div>
        }
    >
        <Button text="Spalten" icon="column-layout"/>
    </Popover>
}