import React from "react";
import BigNumber from "bignumber.js";
import {Table} from "./Table";
import {Card, Classes} from "@blueprintjs/core";
import {DateTime} from "./DateTime";
import {useGlobalContext} from "../GlobalContext";

export function CurrencyViewer() {
    const {data: currencies, loading, reload} = useGlobalContext().currency;

    return (
        <Card style={{padding: 0}} elevation={1}>
            <Table
                style={{width: "100%", maxWidth: "400px"}}
                columns={[
                    {
                        Header: "Von",
                        accessor: "from",
                        width: "60px",
                    },
                    {
                        Header: "Nach",
                        accessor: "to",
                        width: "60px",
                    },
                    {
                        Header: "Wechselkurs",
                        accessor: r => new BigNumber(r.exchange_rate).toFixed(4),
                        width: "30%",
                        Cell: c => {
                            return (<div>
                                <a
                                    href={`https://finance.yahoo.com/quote/${c.row.original.yahoo_id}`}
                                    target="_blank" rel="noopener noreferrer"
                                    className={loading ? Classes.SKELETON : undefined}
                                >
                                    {c.value}
                                </a>
                            </div>)
                            }
                        },
                    {
                        Header: "Datum",
                        accessor: "date",
                        width: "40%",
                        Cell: c => <div className={loading ? Classes.SKELETON : undefined}><DateTime value={c.value}/>
                        </div>
                    },
                    ]}
                    data={currencies}
                    updateSelectedRows={() => {
                    }}
                />
            </Card>
    )
}