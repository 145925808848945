import React, {useMemo} from "react";
import {useFetch} from "../fetch/useFetch";
import {StockChart} from "./StockChart";
import {Spinner} from "../basic/Spinner";
import {Error} from "../basic/Error";
import {Card, Checkbox, H5} from "@blueprintjs/core";
import {DepotStatistics} from "../depot/DepotStatistics";
import {Grid} from "../basic/Grid";
import {useForm} from "react-hook-form";

export function GroupComparison() {
    const {data, loading, error} = useFetch(`/api/depot/statistics`, []);
    const {register, reset, handleSubmit, watch} = useForm();
    const allFields = watch();

    const groups = useMemo(() => {
        let groupMap = new Map();
        data.forEach(d => groupMap.set(d.name, true));
        let fromEntries = Object.fromEntries(groupMap);
        reset(fromEntries);
        return fromEntries;
    }, [data]);

    return (
        <Grid columns="auto 1fr" gap="10px">
            <Card>
                <H5>Gruppen</H5>
                <form>
                    {Object.keys(groups).map(g => {
                        let {name, ref, onChange, onBlur} = register(g)
                        return <Checkbox
                            key={g}
                            name={name}
                            inputRef={ref}
                            onChange={onChange}
                            onBlur={onBlur}
                            defaultChecked={true}
                        >
                            {g}
                        </Checkbox>;
                    })}
                </form>
            </Card>
            <Grid gap="10px">
                <Card>
                    <Spinner loading={loading}>
                        <Error error={error}/>
                        <StockChart
                            lines={data.filter(d => allFields[d.name])}
                        />
                    </Spinner>
                </Card>
                <DepotStatistics groups={data}/>
            </Grid>
        </Grid>
    )
}