import i18n from "i18next";
import {initReactI18next} from "react-i18next";

const resources = {
    de: {
        translation: {
            "companyName": "Firmenname",
            "marketcap": "Market Cap",
            "week52high": "52 Week High",
            "week52low": "52 Week Low",
            "week52highSplitAdjustOnly": "52 Week High Split Adjust Only",
            "week52lowSplitAdjustOnly": "52 Week Low Split Adjust Only",
            "week52change": "52 Week Change",
            "sharesOutstanding": "Shares Outstanding",
            "avg30Volume": "Avg. Volume (30 days)",
            "avg10Volume": "Avg. Volume (10 days)",
            "float": "Float",
            "employees": "Employees",
            "ttmEPS": "EPS (ttm)",
            "ttmDividendRate": "Dividend Rate (ttm)",
            "dividendYield": "Dividend Yiel",
            "nextDividendDate": "Next Dividend Date",
            "exDividendDate": "Ex Dividend Date",
            "nextEarningsDate": "Next Earnings Date",
            "peRatio": "PE Ratio",
            "beta": "Beta",
            "day200MovingAvg": "200-Day Moving Avg.",
            "day50MovingAvg": "50-Day Moving Avg.",
            "maxChangePercent": "Max. Change Percent",
            "year5ChangePercent": "5-Year Change",
            "year2ChangePercent": "2-Year Change",
            "year1ChangePercent": "1-Year Change",
            "ytdChangePercent": "YTD Change",
            "month6ChangePercent": "6-Month Change",
            "month3ChangePercent": "3-Month Change",
            "month1ChangePercent": "1-Month Change",
            "day30ChangePercent": "30-Day Change",
            "day5ChangePercent": "5-Day Change",
        }
    },
};

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        lng: "de",

        interpolation: {
            escapeValue: false // react already safes from xss
        }
    })
    .then(value => console.log("I18n initialized", value));

export default i18n;