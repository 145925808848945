import React, {useEffect, useState} from "react";
import {Button, Divider} from "@blueprintjs/core";
import {CreateEditWatchlistStock} from "./CreateEditWatchlistStock";
import {chain} from "lodash";
import {LabelUpdater} from "../basic/table/LabelUpdater";
import {PriceCellRenderer} from "../basic/table/cellrenderers/PriceCellRenderer";
import {LabelCellRenderer} from "../basic/table/cellrenderers/LabelCellRenderer";
import {EditButtonCellRenderer} from "./EditButtonCellRenderer";
import {Grid} from "../basic/Grid";
import {MacdCellRenderer} from "../basic/table/cellrenderers/MacdCellRenderer";
import {StockDetail} from "../stockdetail/StockDetail";
import {ColumnSelector} from "../basic/table/ColumnSelector";
import {AgTable} from "../basic/table/AgTable";
import {Route, useHistory, useLocation, useParams} from "react-router-dom";
import {useGlobalContext} from "../GlobalContext";

export function Watchlist() {
    const [showCreate, setShowCreate] = useState(false);
    const [editingId, setEditingId] = useState();
    const {data: list, reload} = useGlobalContext().watchlist;
    const [selectedRows, setSelectedRows] = useState([]);
    const groups = chain(list).flatMap(l => l.groups).filter(l => l !== undefined).uniq().sort().value();
    const [api, setApi] = useState();
    const [smallScreen, setSmallScreen] = useState(window.innerWidth < 900);
    const history = useHistory();
    const location = useLocation();
    const shouldHideTable = smallScreen && location.pathname.startsWith("/watchlist/detail");

    useEffect(() => {
        const handleResize = (entries) => {
            const entry = entries[0];
            const width = window.innerWidth;
            setSmallScreen(width < 900);
        }

        window.addEventListener("resize", handleResize);

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return (
        <>
            <CreateEditWatchlistStock
                showDialog={showCreate}
                onCancel={() => setShowCreate(false)}
                onSave={stock => {
                    console.log("Updating", stock);
                    setShowCreate(false);
                    fetch("/api/watchlist", {
                        method: "PUT",
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify(stock)
                    }).finally(() => reload())
                }}
            />
            <CreateEditWatchlistStock
                showDialog={editingId !== undefined}
                onCancel={() => setEditingId(undefined)}
                item={list.filter(l => l._id === editingId)[0]}
                onSave={stock => {
                    updateWatchlistEntry([{...stock, _id: editingId}]).finally(() => reload());
                    setEditingId(undefined);
                }}
            />
            <>
                <Grid rows="1fr" columns={"1fr auto"}>
                    <div style={{display: (shouldHideTable ? "none" : undefined)}}>
                        <Grid
                            rows="auto 1fr" gap="10px"
                            style={{height: "100%", width: "100%"}}
                        >
                            <Grid columns="repeat(6, auto) 1fr" gap="10px">
                                <Button
                                    text="Neue Aktie"
                                    icon="add"
                                    onClick={() => setShowCreate(true)}
                                />
                                <Divider/>
                                <LabelUpdater
                                    groups={groups}
                                    selectedRows={selectedRows}
                                    list={list}
                                    update={stock => updateWatchlistEntry(stock).finally(() => reload())}
                                />
                                <Button
                                    text="Löschen"
                                    icon="delete"
                                    onClick={() => {
                                        return fetch("/api/watchlist/delete", {
                                            method: "POST",
                                            headers: {
                                                "Content-Type": "application/json"
                                            },
                                            body: JSON.stringify(selectedRows)
                                        }).finally(() => {
                                            return reload();
                                        });
                                    }}
                                />
                                <Divider/>
                                <ColumnSelector
                                    gridApi={api}
                                />
                            </Grid>
                            <AgTable
                                id="watchlist"
                                setGridApi={setApi}
                                columnDefs={[
                                    {
                                        headerName: "Symbol",
                                        field: "symbol",
                                        width: 120,
                                        checkboxSelection: true,
                                        sort: "asc",
                                    },
                                    {
                                        headerName: "Name",
                                        field: "name",
                                        width: 200,
                                    },
                                    {
                                        headerName: "Börse",
                                        field: "market",
                                        width: 60,
                                    },
                                    {
                                        headerName: "Währung", field: "currency", width: 80,
                                        cellStyle: {"textAlign": "center"},
                                    },
                                    {
                                        headerName: "Preis",
                                        field: "last_price",
                                        width: 100,
                                        cellStyle: {"textAlign": "center"},
                                        cellRenderer: PriceCellRenderer,
                                        comparator: (valueA, valueB) => {
                                            return (Number(valueA?.close ?? -1000)) - (Number(valueB?.close ?? -1000))
                                        }
                                    },
                                    {
                                        headerName: "MACD",
                                        field: "macd",
                                        width: 100,
                                        cellStyle: {"textAlign": "center"},
                                        cellRenderer: MacdCellRenderer,
                                        comparator: (valueA, valueB) => {
                                            return (valueA?.macd ?? -1000) - (valueB?.macd ?? -1000);
                                        }
                                    },
                                    {
                                        headerName: "P/E",
                                        field: "key_stats.peRatio",
                                        width: 100,
                                        cellStyle: {"textAlign": "center"},
                                        cellRenderer: params => {
                                            if (!params.value) {
                                                return null;
                                            }
                                            return Number(params.value).toFixed()
                                        },
                                    },
                                    {
                                        headerName: "EPS",
                                        field: "key_stats.ttmEPS",
                                        width: 100,
                                        cellStyle: {"textAlign": "center"},
                                        cellRenderer: params => {
                                            if (!params.value) {
                                                return null;
                                            }
                                            return Number(params.value).toFixed(1)
                                        },
                                    },
                                    {
                                        headerName: "ISIN",
                                        field: "isin",
                                        width: 120,
                                        hide: true,
                                    },
                                    {
                                        headerName: "Labels",
                                        field: "groups",
                                        width: 300,
                                        cellRenderer: LabelCellRenderer,
                                        filter: "groupsFilter",
                                    },
                                    {
                                        headerName: "Kommentar",
                                        field: "comment",
                                        width: 300,
                                    },
                                    {
                                        headerName: "Edit",
                                        field: "_id",
                                        cellRenderer: EditButtonCellRenderer,
                                        cellRendererParams: {setEditingId: setEditingId},
                                        filter: false,
                                        width: 50,
                                    }
                                ]}
                                rowData={list}
                                onSelectionChanged={e => {
                                    setSelectedRows(e.api.getSelectedNodes().map(n => n.data._id));
                                }}
                                onRowDoubleClicked={e => {
                                    history.push(`/watchlist/detail/${e.data.symbol}/${e.data.market}`)
                                }}
                            />
                        </Grid>
                    </div>
                    <Route path="/watchlist/detail/:symbol">
                        <div
                            style={{
                                marginLeft: shouldHideTable ? "0px" : "10px"
                            }}
                        >
                            <StockDetailPage/>
                        </div>
                    </Route>
                </Grid>
            </>
        </>
    )
        ;
}

function StockDetailPage() {
    const history = useHistory();
    const {market, symbol} = useParams();

    return (
        <StockDetail
            symbol={symbol}
            market={market}
            onClose={() => history.push("/watchlist")}
        />
    )
}


function updateWatchlistEntry(stocks) {
    console.log("Saving watchlist", stocks);
    return fetch("/api/watchlist/update", {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(stocks)
    })
}

