import React from "react";

export const formatter = Intl.DateTimeFormat("de-CH", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
});

export function DateTime({value, dateOnly}) {
    if (value === undefined) {
        return <>kein Datum</>
    }

    const date = new Date(value);

    return (
        <>{formatter.format(date)} {!dateOnly && date.toLocaleTimeString("de-CH")}</>
    )
}