import React, {useState} from "react";
import {useFetch} from "../fetch/useFetch";
import {Spinner} from "../basic/Spinner";
import {Card, HTMLTable} from "@blueprintjs/core";
import {DateTime} from "../basic/DateTime";

export function TaxStatementPage() {
    const [year, setYear] = useState("2023")
    const {data, loading, reload} = useFetch(`/api/taxstatement/${year}`);

    if (loading) {
        return <Spinner/>
    }

    console.log(data)

    return <>
        <h1>Steuerauszug {year}</h1>
        {Object.entries(data.accounts)
            .map(([accountName, stocks]) => {
                return <div key={accountName}>
                    <h2>{accountName}</h2>
                    {stocks.map(stock => {
                        return <div key={stock.symbol}>
                            <h3>{stock.symbol}</h3>
                            <p>Menge Anfangs Jahr: {stock.quantity_start_of_year}</p>
                            <p>Menge Ende Jahr: {stock.quantity_end_of_year}</p>
                            {stock.transactions.length > 0 &&
                                <div style={{display: "inline-block"}}>
                                    <Card style={{padding: 0}} elevation={1}>
                                        <HTMLTable striped bordered compact>
                                            <thead>
                                            <tr>
                                                <th>Date</th>
                                                <th>Typ</th>
                                                <th>Menge</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {stock.transactions.map(tx =>
                                                <tr key={tx.date}>
                                                    <td><DateTime dateOnly value={tx.date}/></td>
                                                    <td>{tx.type}</td>
                                                    <td>{tx.quantity}</td>
                                                </tr>
                                            )}
                                            </tbody>
                                        </HTMLTable>
                                    </Card>
                                </div>}
                        </div>
                    })}
                </div>
            })}
    </>
}