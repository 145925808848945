import React, {useEffect, useMemo, useState} from "react";
import {Button, Card, H4, HTMLTable, Popover, Text} from "@blueprintjs/core";
import {Money} from "../basic/Money";
import {BigNumber} from "bignumber.js";
import {chain, filter, map, reduce} from "lodash";
import {Grid} from "../basic/Grid";
import {DecimalMoneyCellRenderer, MoneyCellRenderer} from "./MoneyCellRenderer";
import {NavLink} from "react-router-dom";
import {AddManualDepotEntry} from "./AddManualDepotEntry";
import {CurrencyViewer} from "../basic/CurrencyViewer";
import {MacdCellRenderer} from "../basic/table/cellrenderers/MacdCellRenderer";
import {Percentage} from "../basic/Percentage";
import {formatter} from "../basic/DateTime";
import {DepotEntryDetail} from "./DepotEntryDetail";
import {AgTable} from "../basic/table/AgTable";
import {ColumnSelector} from "../basic/table/ColumnSelector";
import {LabelUpdater} from "../basic/table/LabelUpdater";
import {LabelCellRenderer} from "../basic/table/cellrenderers/LabelCellRenderer";
import {Align} from "../basic/Align";
import {DepotRefresh} from "./DepotRefresh";
import {useGlobalContext} from "../GlobalContext";

export function Depot() {
    const {data: list, reload} = useGlobalContext().depot;
    const userSettings = useGlobalContext().userSettings.data;
    const [selectedRows, setSelectedRows] = useState([]);
    const [api, setApi] = useState();
    const [cashApi, setCashApi] = useState();
    const [structuredProductApi, setStructuredProductApi] = useState();

    const calculated = useMemo(() => {
        return map(list, oldobj => {
            let v = {...oldobj}
            v.unitcost = new BigNumber(v.unitcost);
            v.lastprice = new BigNumber(v.lastprice);
            v.quantity = new BigNumber(v.quantity);
            v.totalvalue = new BigNumber(v.totalvalue);
            v.totalvalueChf = new BigNumber(v.totalvalueChf);
            v.percentage = new BigNumber(v.percentage);
            v.totalBuyValueChf = new BigNumber(v.totalBuyValueChf);
            v.gain = new BigNumber(v.gain);
            let firstOpenOfYear = new BigNumber(v.firstOpenOfYear);
            v.ytd = v.lastprice.minus(firstOpenOfYear).dividedBy(firstOpenOfYear);
            v.cash = v.cash ?? {};
            v.dividends = new BigNumber(v.dividends ?? 0)
            v.fees = new BigNumber(v.fees ?? 0)
            v.totalGain = new BigNumber(v.totalGain)
            v.totalGainChf = new BigNumber(v.totalGainChf)
            return v;
        });
    }, [list]);

    let depotRows = useMemo(() => filter(calculated, o => o.type === "stock"), [list]);
    let structuredProductRows = useMemo(() => {
        return filter(calculated, o => o.type === "structured_product")
            .map(e => ({
                ...e,
                interest: new BigNumber(e.name.split(" ", 1)[0]),
            }));
    }, [list]);
    let cashRows = useMemo(() => filter(calculated, o => o.type === "crypto" || o.type === "cash" || o.type === "trust_investment"), [list]);

    const overviewTotals = useMemo(() => {
        return chain(calculated)
            .groupBy("type")
            .mapValues(depotEntries => reduce(depotEntries, (sum, n) => sum.plus(n.totalvalueChf), new BigNumber(0)))
            .value()
    }, [calculated])

    const groups = chain(list)
        .flatMap(l => l.groups)
        .filter(l => l !== undefined)
        .uniq()
        .sort()
        .value();

    const [totals, setTotals] = useState({
        totalValueChf: new BigNumber(0),
        totalGainChf: new BigNumber(0),
        totalValueChfCryptoAndCash: new BigNumber(0),
    });

    const recalculateTotals = () => {
        let totalValueChf = new BigNumber(0);
        let totalGainChf = new BigNumber(0);
        api?.forEachNodeAfterFilter(({data}) => {
            totalValueChf = totalValueChf.plus(data.totalvalueChf);
            totalGainChf = totalGainChf.plus(data.totalGainChf);
        })
        let totalValueChfCryptoAndCash = new BigNumber(0);
        cashApi?.forEachNodeAfterFilter(({data}) => {
            totalValueChfCryptoAndCash = totalValueChfCryptoAndCash.plus(data.totalvalueChf);
        })
        setTotals({
            totalValueChf,
            totalGainChf,
            totalValueChfCryptoAndCash
        });
    };

    useEffect(() => {
        recalculateTotals()
    }, [api, cashApi])

    let totalInvestment = (overviewTotals?.stock ?? new BigNumber(0))
        .plus(overviewTotals?.cash ?? new BigNumber(0))
        .plus(overviewTotals?.crypto ?? new BigNumber(0))
        .plus(overviewTotals?.trust_investment ?? new BigNumber(0))
        .plus(overviewTotals?.structured_product ?? new BigNumber(0));

    return (
        <Grid rows="auto auto auto auto" gap="10px">
            <div style={{display: "flex", flexWrap: "wrap", gap: "10px", alignItems: "start"}}>
                <CurrencyViewer/>
                <Card style={{padding: 0}} elevation={1}>
                    <HTMLTable striped bordered compact>
                        <thead>
                        <tr>
                            <th colSpan="3">Depot Übersicht</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>Aktien</td>
                            <td style={{textAlign: "right"}}><Money value={overviewTotals.stock}/></td>
                            <td><Percentage value={overviewTotals.stock?.div(totalInvestment)}/></td>
                        </tr>
                        <tr>
                            <td>Cash</td>
                            <td style={{textAlign: "right"}}><Money value={overviewTotals.cash}/></td>
                            <td><Percentage value={overviewTotals.cash?.div(totalInvestment)}/></td>
                        </tr>
                        <tr>
                            <td>Crypto</td>
                            <td style={{textAlign: "right"}}><Money value={overviewTotals.crypto ?? new BigNumber(0)}/>
                            </td>
                            <td><Percentage value={overviewTotals.crypto?.div(totalInvestment)}/></td>
                        </tr>
                        {overviewTotals.structured_product && <tr>
                            <td>Str. Produkte</td>
                            <td style={{textAlign: "right"}}><Money
                                value={overviewTotals.structured_product ?? new BigNumber(0)}
                            /></td>
                            <td><Percentage value={overviewTotals.structured_product?.div(totalInvestment)}/></td>
                        </tr>}
                        {overviewTotals.trust_investment && <tr>
                            <td>Festgeld</td>
                            <td style={{textAlign: "right"}}><Money
                                value={overviewTotals.trust_investment ?? new BigNumber(0)}
                            /></td>
                            <td><Percentage value={overviewTotals.trust_investment?.div(totalInvestment)}/></td>
                        </tr>}
                        <tr>
                            <td><b>Total</b></td>
                            <td style={{textAlign: "right"}}>
                                <Money value={totalInvestment}/>
                            </td>
                            <td></td>
                        </tr>
                        </tbody>
                    </HTMLTable>
                </Card>
            </div>
            <Header>Aktien</Header>
            <Grid columns="repeat(4, auto) 1fr" gap="10px" justify="end">
                <LabelUpdater
                    list={list}
                    groups={groups}
                    selectedRows={selectedRows}
                    update={rows => updateDepotGroup(rows).then(() => reload())}
                />
                <AddManualDepotEntry
                    reloadDepot={reload}
                />
                <Button
                    text="Eintrag Löschen"
                    icon="delete"
                    onClick={() => {
                        fetch("/api/depot/delete", {
                            method: "POST",
                            headers: {
                                "Content-Type": "application/json"
                            },
                            body: JSON.stringify(selectedRows)
                        }).finally(() => reload())
                    }}
                />
                <DepotRefresh reloadDepot={reload}/>
                <ColumnSelector
                    gridApi={api}
                />
            </Grid>
            {userSettings && <AgTable
                id="depot"
                setGridApi={setApi}
                columnDefs={[
                    {
                        headerName: "Symbol",
                        field: "symbol",
                        checkboxSelection: true,
                        width: 150,
                        cellRenderer: ({value, data: original}) => {
                            return (<>
                                <Grid align="center" columns="fit-content(0) auto" gap="5px">
                                    {value && <Popover
                                        usePortal
                                        preventOverflow
                                        boundary={"window"}
                                        content={
                                            <div
                                                style={{
                                                    maxWidth: "800px",
                                                    maxHeight: "500px",
                                                    overflow: "auto",
                                                    padding: "20px"
                                                }}
                                            >
                                                <DepotEntryDetail
                                                    depotEntry={original}
                                                    performanceStartDate={userSettings?.performance_start_date}
                                                />
                                            </div>
                                        }
                                    >
                                        <Button icon={"properties"} minimal small/>
                                    </Popover>}
                                    <NavLink to={`/stocks/${original?.symbol}/${original?.market ?? ""}`}>{value}</NavLink>
                                </Grid>
                            </>)
                        },
                    },
                    {
                        headerName: "Name",
                        field: "name",
                        cellRenderer: ({value}) => <Text ellipsize>{value}</Text>,
                    },
                    {
                        headerName: "P/E",
                        field: "price_earnings",
                        width: 100,
                        cellRenderer: ({value}) => <Align center>
                            <Money value={new BigNumber(value)} places={0}/>
                        </Align>,
                    },
                    {
                        headerName: "EPS",
                        field: "earnings_per_share",
                        width: 100,
                        cellRenderer: ({value}) => <Align center>
                            <Money value={new BigNumber(value)} places={1}/>
                        </Align>,
                    },
                    {
                        headerName: "MACD",
                        field: "macd",
                        width: 100,
                        cellRenderer: MacdCellRenderer,
                        comparator: (valueA, valueB) => {
                            return (valueA?.macd ?? -1000) - (valueB?.macd ?? -1000);
                        }
                    },
                    {
                        headerName: "Währung",
                        field: "currency",
                        width: 100,
                        cellRenderer: ({value}) => <Align center>{value}</Align>,
                    },
                    {
                        headerName: "Menge",
                        field: "quantity",
                        width: 100,
                        cellRenderer: MoneyCellRenderer,
                        comparator: bigNumberComparator,
                    },
                    {
                        headerName: "EK",
                        field: "unitcost",
                        width: 100,
                        cellRenderer: MoneyCellRenderer,
                        comparator: bigNumberComparator,
                    },
                    {
                        headerName: "Kurs",
                        field: "lastprice",
                        width: 100,
                        cellRenderer: MoneyCellRenderer,
                        comparator: bigNumberComparator,
                    },
                    {
                        headerName: "Total",
                        field: "totalvalue",
                        width: 100,
                        cellRenderer: MoneyCellRenderer,
                        hide: true,
                        comparator: bigNumberComparator,
                    },
                    {
                        headerName: "Total CHF",
                        field: "totalvalueChf",
                        width: 100,
                        cellRenderer: MoneyCellRenderer,
                        comparator: bigNumberComparator,
                    },
                    {
                        headerName: "YTD",
                        field: "ytd",
                        width: 100,
                        cellRenderer: ({value}) => <Align right><Percentage value={value}/></Align>,
                        comparator: bigNumberComparator,
                    },
                    {
                        headerName: "G&V",
                        field: "gain",
                        width: 150,
                        cellRenderer: ({value}) => <Align right><Money value={value} colored/></Align>,
                        comparator: bigNumberComparator,
                    },
                    {
                        headerName: "Gewinn CHF",
                        headerValueGetter: ({context}) => {
                            return `Gewinn CHF seit ${userSettings.performance_start_date && formatter.format(new Date(userSettings.performance_start_date))}`
                        },
                        field: "totalGainChf",
                        width: 200,
                        cellRenderer: ({value}) => <Align right><Money value={value} colored/></Align>,
                        comparator: bigNumberComparator,
                    },
                    {
                        headerName: "Labels",
                        field: "groups",
                        width: 150,
                        cellRenderer: LabelCellRenderer,
                        filter: "groupsFilter",
                    },
                    {
                        headerName: "ID",
                        field: "_id",
                        cellRenderer: ({value}) => <Text ellipsize>{value}</Text>,
                        hide: true,
                    },
                ]}
                rowData={depotRows}
                onSelectionChanged={e => {
                    setSelectedRows(e.api.getSelectedNodes().map(n => n.data._id));
                }}
                onModelUpdated={recalculateTotals}
            />}
            <Card compact>
                <Grid columns="auto auto" gap="20px">
                    <div>
                        <span>Total Wert:{" "}</span>
                        <span style={{fontWeight: "600"}}>
                            <Money value={totals.totalValueChf} colored/>
                        </span>
                    </div>
                    <div>
                        <span>Total Gewinn:{" "}</span>
                        <span style={{fontWeight: "600"}}>
                            <Money value={totals.totalGainChf} colored/>
                        </span>
                    </div>
                </Grid>
            </Card>

            {structuredProductRows?.length > 0 && <>
                <Grid columns="1fr auto">
                    <Header>
                        Strukturierte Produkte
                    </Header>
                    <ColumnSelector
                        gridApi={structuredProductApi}
                    />
                </Grid>
                <AgTable
                    id="structured_products"
                    setGridApi={setStructuredProductApi}
                    rowData={structuredProductRows}
                    columnDefs={[
                        {
                            headerName: "Symbol",
                            field: "symbol",
                            cellRenderer: ({value}) => <Text ellipsize>{value}</Text>,
                        },
                        {
                            headerName: "Name",
                            field: "name",
                            cellRenderer: ({value}) => <Text ellipsize>{value}</Text>,
                        },
                        {
                            headerName: "Zins",
                            field: "interest",
                            width: 150,
                            cellRenderer: ({value}) => <Align right><Money value={value} places={3} colored/></Align>,
                            comparator: bigNumberComparator,
                        },
                        {
                            headerName: "Total",
                            field: "totalvalue",
                            width: 100,
                            cellRenderer: MoneyCellRenderer,
                            hide: true,
                            comparator: bigNumberComparator,
                        },
                        {
                            headerName: "Währung",
                            field: "currency",
                            width: 100,
                            cellRenderer: ({value}) => <Align center>{value}</Align>,
                        },
                        {
                            headerName: "Total CHF",
                            field: "totalvalueChf",
                            width: 100,
                            cellRenderer: MoneyCellRenderer,
                            comparator: bigNumberComparator,
                        },
                        {
                            headerName: "ID",
                            field: "_id",
                            cellRenderer: ({value}) => <Text ellipsize>{value}</Text>,
                            hide: true,
                        },
                    ]}
                />
            </>}

            <Grid columns="1fr auto">
                <Header>
                    Cash, Crypto & Festgeld
                </Header>
                <div style={{float: "right"}}>
                    <ColumnSelector
                        gridApi={cashApi}
                    />
                </div>
            </Grid>

            <AgTable
                id="cash"
                setGridApi={setCashApi}
                columnDefs={[
                    {
                        headerName: "Symbol",
                        field: "symbol",
                        width: 150,
                        cellRenderer: ({value, data: original}) => {
                            return (<>
                                <Grid align="center" columns="fit-content(0) auto" gap="5px">
                                    {original.currencies && <Popover
                                        usePortal
                                        preventOverflow
                                        boundary={"window"}
                                        content={
                                            <div
                                                style={{
                                                    maxWidth: "800px",
                                                    maxHeight: "500px",
                                                    overflow: "auto",
                                                    padding: "20px"
                                                }}
                                            >
                                                <DepotEntryDetail
                                                    depotEntry={original}
                                                    performanceStartDate={userSettings?.performance_start_date}
                                                />
                                            </div>
                                        }
                                    >
                                        <Button icon={"properties"} minimal small/>
                                    </Popover>}
                                    {original.type === "crypto" ?
                                        <NavLink to={`/stocks/${value}`}>{value}</NavLink> : <>{value}</>}
                                </Grid>
                            </>)
                        },
                    },
                    {
                        headerName: "Name",
                        field: "name",
                        cellRenderer: ({value}) => <Text ellipsize>{value}</Text>,
                    },
                    {
                        headerName: "Währung",
                        field: "currency",
                        width: 100,
                        cellRenderer: ({value}) => <Align center>{value}</Align>,
                    },
                    {
                        headerName: "Menge",
                        field: "quantity",
                        width: 100,
                        cellRenderer: DecimalMoneyCellRenderer,
                        comparator: bigNumberComparator,
                    },
                    {
                        headerName: "EK",
                        field: "unitcost",
                        width: 100,
                        cellRenderer: MoneyCellRenderer,
                        comparator: bigNumberComparator,
                    },
                    {
                        headerName: "Kurs",
                        field: "lastprice",
                        width: 100,
                        cellRenderer: MoneyCellRenderer,
                        comparator: bigNumberComparator,
                    },
                    {
                        headerName: "Total CHF",
                        field: "totalvalueChf",
                        width: 100,
                        cellRenderer: MoneyCellRenderer,
                        comparator: bigNumberComparator,
                    },
                    {
                        headerName: "YTD",
                        field: "ytd",
                        width: 100,
                        cellRenderer: ({value}) => <Align right><Percentage value={value}/></Align>,
                        comparator: bigNumberComparator,
                    },
                    {
                        headerName: "G&V",
                        field: "gain",
                        width: 150,
                        cellRenderer: ({value}) => <Align right><Money value={value} colored/></Align>,
                        comparator: bigNumberComparator,
                    },
                    {
                        headerName: "ID",
                        field: "_id",
                        cellRenderer: ({value}) => <Text ellipsize>{value}</Text>,
                        hide: true,
                    },
                ]}
                rowData={cashRows}
                onModelUpdated={recalculateTotals}
            />
            <Card compact>
                <Grid columns="auto auto" gap="20px">
                    <div>
                        <span>Total:{" "}</span>
                        <span style={{fontWeight: "600"}}>
                            <Money value={totals.totalValueChfCryptoAndCash} colored/>
                        </span>
                    </div>
                </Grid>
            </Card>
        </Grid>
    );
}

function bigNumberComparator(valueA, valueB) {
    return valueA.minus(valueB).toNumber();
}

function Header({children}) {
    return <H4 style={{margin: "10px 0 0 0"}}>{children}</H4>;
}

function updateDepotGroup(stocks) {
    return fetch("/api/depot/update", {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(stocks)
    })
}