import React, {useState} from "react";
import {Grid} from "../basic/Grid";
import {Button, Card, H2, H3, HTMLTable, Text} from "@blueprintjs/core";
import {Money} from "../basic/Money";
import {BigNumber} from "bignumber.js";
import {useFetch} from "../fetch/useFetch";
import {Spinner} from "../basic/Spinner";
import {Error} from "../basic/Error";
import {StockChart} from "./StockChart";
import {DateTime} from "../basic/DateTime";

import yahooLogo from "./yahoo_logo.jpg"
import googleLogo from "./google_logo.svg"
import bitcoinLogo from "./bitcoin-btc-logo.svg"
import ethereumLogo from "./ethereum-eth-logo.svg"
import {Expand} from "../basic/Expand";
import {useTranslation} from "react-i18next";

const cryptoLogoLookup = {
    XBT: bitcoinLogo,
    BTC: bitcoinLogo,
    ETH: ethereumLogo,
}

export function StockDetail({symbol, market, onClose}) {
    const encodedSymbol = encodeURIComponent(encodeURIComponent(symbol));
    let {data, reload, loading, error} = useFetch(`/api/stocks/${encodedSymbol}/${market ?? ""}`, null);
    const [refreshDataLoading, setRefreshDataLoading] = useState(false);
    const {t} = useTranslation();

    return (
        <Spinner loading={loading || refreshDataLoading}>
            <Error error={error}/>
            {data && !error &&
            <Grid gap="10px">
                <Card>
                    <Grid columns="auto auto 1fr auto" gap="20px" align="center">
                        <H2 style={{maxWidth: "400px"}}>
                            {data.market === "CRYPTO" &&
                                <img
                                    src={cryptoLogoLookup[data.symbol]}
                                    height="25"
                                    alt=""
                                />}
                            {data.market !== "CRYPTO" &&
                                <img
                                    src={`https://storage.googleapis.com/iex/api/logos/${data.symbol}.png`}
                                    height="25"
                                    alt=""
                                />}
                            {" "}
                            {data.symbol}.{data.market} {data.company_info?.companyName && <>: {data.company_info?.companyName}</>}
                        </H2>
                        <a
                            href={`https://www.google.com/search?q=${data.symbol}&tbm=fin`}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <img
                                src={googleLogo}
                                alt="Google"
                                width="30"
                            />
                        </a>
                        <a
                            href={`https://finance.yahoo.com/quote/${data.symbol}`}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <img
                                src={yahooLogo}
                                alt="Yahoo"
                                width="50"
                            />
                        </a>
                        <div>{onClose && <Button icon="cross" minimal onClick={onClose}/>}</div>
                    </Grid>
                </Card>

                {data.latest_price && <Card>
                    <HTMLTable compact minimal="true">
                        <thead>
                        <tr>
                            <th>Aktueller Kurs</th>
                            <th>Zeit</th>
                            <th>Quelle</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>{data.latest_price}</td>
                            <td><DateTime value={data.latest_update}/></td>
                            <td>{data.latest_source}</td>
                        </tr>
                        </tbody>
                    </HTMLTable>
                </Card>}

                <Card>
                    <StockChart lines={[data]}/>
                </Card>
                {/*{data.price_target && <Card>*/}
                {/*    <H2>Analysten Preis Ziel</H2>*/}
                {/*    <PriceTargetChart data={data}/>*/}
                {/*</Card>}*/}
                {data.info && <Card>
                    <H2>Fundamentals</H2>
                    <div style={{paddingBottom: "10px", width: "400px"}}>
                        <Text ellipsize>{data.info.longBusinessSummary}</Text>
                    </div>
                    {!data.symbol.startsWith("_GROUP_") &&
                        <HTMLTable compact striped>
                            <tbody>
                            <tr>
                                <td>ISIN</td>
                                <td>{data.isin}</td>
                            </tr>
                            <tr>
                                <td>Angestellte</td>
                                <td>{data.info.fullTimeEmployees}</td>
                            </tr>
                            <tr>
                            <td>Market Cap</td>
                            <td><Money value={new BigNumber(data.info.marketCap)}/> {data.info.currency}</td>
                        </tr>
                        <tr>
                            <td>Enterprise Value</td>
                            <td><Money value={new BigNumber(data.info.enterpriseValue)}/> {data.info.currency}</td>
                        </tr>
                        <tr>
                            <td>PE Ratio</td>
                            <td><Money value={new BigNumber(data.key_stats?.peRatio)} places={2}/></td>
                        </tr>
                        <tr>
                            <td>Forward PE</td>
                            <td><Money value={new BigNumber(data.info.forwardPE)} places={2}/></td>
                        </tr>
                        <tr>
                            <td>Price/Sales Ratio</td>
                            <td><Money value={new BigNumber(data.info.priceToSalesTrailing12Months)} places={2}/></td>
                        </tr>
                        <tr>
                            <td>Dividend Yield</td>
                            <td><Money value={new BigNumber(data.key_stats?.dividendYield)} places={2}/></td>
                        </tr>
                        </tbody>
                    </HTMLTable>}
                    {data.key_stats &&
                    <Expand text="Weiteres...">
                        <HTMLTable compact striped>
                            <tbody>
                            {
                                Object.entries(data.key_stats)
                                    .map(([key, value]) => {
                                        return <tr key={key}>
                                            <td>{t(key)}</td>
                                            <td>{value}</td>
                                        </tr>
                                    })
                            }
                            </tbody>
                        </HTMLTable>
                    </Expand>}
                </Card>}
                <Card>
                    <H3>Letzte Updates <Button
                        icon="refresh" onClick={() => {
                        setRefreshDataLoading(true);
                        fetch(`/api/stocks/${encodedSymbol}/${data.market}/refresh`, {method: "POST"})
                            .finally(() => {
                                reload();
                                setRefreshDataLoading(false);
                            })
                    }}
                    /></H3>
                    <HTMLTable compact striped>
                        <thead>
                        <tr>
                            <th>Was</th>
                            <th>Letztes Update</th>
                            <th>Fehler</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>Kurse</td>
                            <td><DateTime value={data.history_last_update}/></td>
                            <td style={{maxWidth: "300px"}}>{data.history_update_error}</td>
                        </tr>
                        <tr>
                            <td>MACD</td>
                            <td><DateTime value={data.macd_last_update}/></td>
                            <td style={{maxWidth: "300px"}}>{data.macd_update_error}</td>
                        </tr>
                        </tbody>
                    </HTMLTable>
                </Card>
            </Grid>}
        </Spinner>
    );
}