import {useEffect, useState} from "react";
import {useLoginContext} from "../LoginContext";

export function useFetch(url, initial = []) {
    const [data, setData] = useState(initial);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [abortControllers, setAbortControllers] = useState([]);
    const {loggedIn, setLoggedIn} = useLoginContext()

    let loadingFunc = () => {
        const abortController = new AbortController();
        setAbortControllers(prev => [...prev, abortController])
        setError(false);
        console.log(`Loading: ${url}`)
        return fetch(url, {signal: abortController.signal})
            .then(response => {
                if (response.status === 200) {
                    return response.json();
                } else if (response.status === 401) {
                    setLoggedIn(false);
                    return Promise.reject(response.json());
                } else {
                    return Promise.reject(response.json());
                }
            })
            .then(response => setData(response))
            .catch(error => {
                setError(true);
                return error;
            })
            .finally(() => setLoading(false));
    };

    useEffect(() => {
        if (loggedIn === true) {
            setLoading(true);
            loadingFunc();
            return () => abortControllers.forEach(abortController => abortController.abort());
        } else {
            return () => {
            };
        }
    }, [url, loggedIn]);

    return {
        data: data,
        reload: loadingFunc,
        loading: loading,
        error: error,
        setData: setData // Allows directly updating the cached data without reloading
    };
}

export function useFetchWithBody(url) {
    const [data, setData] = useState([]);

    return {
        data: data,
        reload: (params) => fetch(url, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(params)
        })
            .then(response => response.json())
            .then(response => setData(response))
    };
}