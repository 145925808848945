import React from "react";

export function Money({value, places, colored = false}) {
    let color = undefined;
    if (colored) {
        color = value?.isGreaterThan(0) ? "green" : "red"
    }
    return (
        <span
            style={{color: color}}
        >
            {value && !value.isNaN() && value.toFormat(places ? places : value.isLessThan(2) && value.isGreaterThan(-2) ? 2 : 0)}
        </span>
    );
}