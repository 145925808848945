import {useForm} from "react-hook-form";
import {Button, ButtonGroup, Classes, Dialog, FormGroup, InputGroup} from "@blueprintjs/core";
import React from "react";
import {MarketSelect} from "../basic/MarketSelect";

export function CreateEditDepotEntry({showDialog, onCancel, onSave, item}) {
    return (
        <Dialog
            title="Neue Aktie"
            enforceFocus
            isOpen={showDialog}
            onClose={onCancel}
        >
            <Form
                onCancel={onCancel}
                onSave={onSave}
                item={item}
            />
        </Dialog>
    );
}

function Form({onCancel, onSave, item}) {
    let {register, handleSubmit} = useForm({defaultValues: item ?? {}});

    let symbol = register("symbol");
    let name = register("name");
    let market = register("market");
    let currency = register("currency");
    let quantity = register("quantity");
    let unitCost = register("unitcost");

    return (
        <form onSubmit={handleSubmit(onSave)}>
            <div className={Classes.DIALOG_BODY}>
                <FormGroup label="Aktie (Symbol)">
                    <InputGroup
                        autoFocus
                        inputRef={symbol.ref}
                        name={symbol.name}
                        onChange={symbol.onChange}
                        onBlur={symbol.onBlur}
                    />
                </FormGroup>
                <FormGroup label="Name">
                    <InputGroup
                        inputRef={name.ref}
                        name={name.name}
                        onChange={name.onChange}
                        onBlur={name.onBlur}
                    />
                </FormGroup>
                <FormGroup label="Börse">
                    <MarketSelect name="market" register={register}/>
                </FormGroup>
                <FormGroup label="Währung">
                    <InputGroup
                        inputRef={currency.ref}
                        name={currency.name}
                        onChange={currency.onChange}
                        onBlur={currency.onBlur}
                    />
                </FormGroup>
                <FormGroup label="Menge">
                    <InputGroup
                        inputRef={quantity.ref}
                        name={quantity.name}
                        onChange={quantity.onChange}
                        onBlur={quantity.onBlur}
                    />
                </FormGroup>
                <FormGroup label="Einkaufspreis">
                    <InputGroup
                        inputRef={unitCost.ref}
                        name={unitCost.name}
                        onChange={unitCost.onChange}
                        onBlur={unitCost.onBlur}
                    />
                </FormGroup>
            </div>
            <div className={Classes.DIALOG_FOOTER}>
                <ButtonGroup>
                    <Button text="Speichern" intent="primary" type="submit"/>
                    <Button text="Abbrechen" onClick={onCancel}/>
                </ButtonGroup>
            </div>
        </form>
    );
}