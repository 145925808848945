import React, {useState} from "react";
import {Button, Collapse} from "@blueprintjs/core";

export function Expand({text, children}) {
    const [open, setOpen] = useState(false);
    return (
        <>
            <Button
                minimal
                onClick={() => setOpen(prev => !prev)}
            >
                {open ? "v" : ">"} {text}
            </Button>
            <Collapse isOpen={open}>
                {children}
            </Collapse>
        </>
    );
}