import React, {useMemo} from "react";

export function Grid({columns, rows, children, gap, style, justify, align}) {
    const memoizedStyle = useMemo(() => ({
        display: "grid",
        gridTemplateColumns: columns,
        gridTemplateRows: rows,
        gridGap: gap,
        alignItems: align,
        justifyItems: justify, ...style
    }), [columns, rows, gap, justify, align]);
    return (
        <div style={memoizedStyle}>
            {children}
        </div>
    );
}