import React from "react";

export class LabelCellRenderer {
    element;

    init(params) {
        this.render(params);
    }

    getGui() {
        return this.element;
    }

    render(params) {
        this.element = document.createElement('div');
        params.value && params.value.map(v => {
            let tag = document.createElement('span');
            tag.setAttribute("class", "bp5-tag bp5-minimal");
            tag.setAttribute("style", "margin-right: 2px;");
            tag.innerText = v;
            this.element.appendChild(tag);
        });
    }


    // Mandatory - Get the cell to refresh. Return true if the refresh succeeded, otherwise return false.
    // If you return false, the grid will remove the component from the DOM and create
    // a new component in its place with the new values.
    refresh() {
        return true;
    }
}