import React from "react";

export function Align({children, right = true, center = false}) {
    let align = "left";
    if (right) align = "right";
    if (center) align = "center";

    return <div
        style={{textAlign: align}}
    >
        {children}
    </div>
}