import React from "react";
import {createRoot} from "react-dom/client";
import * as Sentry from "@sentry/react";
import "./i18n";
import {App} from "./components/App";

import "normalize.css";
import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/icons/lib/css/blueprint-icons.css";

console.log("Current environment:", process.env.NODE_ENV);

if (process.env.NODE_ENV !== "development") {
    console.log("Initializing sentry", process.env.NODE_ENV)
    Sentry.init({
        dsn: "https://0836a59bbb1f4b9da1f191067cbbaee2@o466536.ingest.sentry.io/5480925",
        integrations: [
            Sentry.browserTracingIntegration(),
            Sentry.replayIntegration(),
            Sentry.feedbackIntegration({
                autoInject: false
            }),
        ],

        tracesSampleRate: 1.0,

        environment: process.env.NODE_ENV,

        // Session Replay
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0, // change the sample rate to 100% when sampling sessions where errors occur.
    });
}

const root = createRoot(document.getElementById("app"));
root.render(<App/>)
