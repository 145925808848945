import React from "react";
import {useFetch} from "../fetch/useFetch";
import {Card, HTMLTable} from "@blueprintjs/core";
import {Money} from "../basic/Money";
import {BigNumber} from "bignumber.js";
import {useParams} from "react-router-dom";

export function BudgetYear() {
    let {year} = useParams();
    const {data: transactions, reload} = useFetch(`/api/budget/transactions?year=${year}`);

    let categories = new Set();
    let sums = new Map();
    transactions.forEach(t => {
        let date = new Date(t.date);
        let month = date.getMonth();
        categories.add(t.category);
        let key = t.category + "-" + month;
        let currentSum = sums.get(key) ?? new BigNumber(0);
        currentSum = currentSum.plus(new BigNumber(t.amount));
        sums.set(key, currentSum);
    });
    let months = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
    let monthNames = ["Jan", "Feb", "Mär", "Apr", "Mai", "Jun", "Jul", "Aug", "Sep", "Okt", "Nov", "Dez"]

    return (
        <>
            <h1>Budget</h1>
            <h3>Groups</h3>
            <div style={{display: "inline-block"}}>
                <Card style={{padding: 0}} elevation={1}>
                    <HTMLTable striped bordered compact>
                        <thead>
                        <tr>
                            <th key={"cat"}>Kategorie</th>
                            {months.map(y => <th key={y}>{monthNames[y]}</th>)}
                        </tr>
                        </thead>
                        <tbody>
                        {Array.from(categories.values()).map(cat =>
                            <tr key={cat}>
                                <td>{cat}</td>
                                {months.map(y =>
                                    <td key={y}>
                                        <Money value={sums.get(cat + "-" + y)} places={0}/>
                                    </td>
                                )}
                            </tr>
                        )}
                        </tbody>
                    </HTMLTable>
                </Card>
            </div>

            <h3>Alle</h3>
            <Card style={{padding: 0}} elevation={1}>
                <HTMLTable striped bordered compact>
                    <thead>
                    <tr>
                        <th>Datum</th>
                        <th>Betrag</th>
                        <th>Kategorie</th>
                        <th>description</th>
                    </tr>
                    </thead>
                    <tbody>
                    {transactions.map((t, idx) => {
                        return <tr key={idx}>
                            <td>{t.date}</td>
                            <td style={{textAlign: "right"}}>
                                <Money value={new BigNumber(t.amount)}/>
                            </td>
                            <td>{t.category}</td>
                            <td>{t.description}</td>
                        </tr>
                    })}

                    </tbody>
                </HTMLTable>
            </Card>
        </>
    )
}