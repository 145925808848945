import React from "react";

export function Percentage({value}) {
    return (
        <>
            {value && !value.isNaN() &&
            <span style={value.comparedTo(0) > 0 ? greenStyle : redStyle}>
                {value.multipliedBy(100).toFormat(0)}%
            </span>}
        </>
    );
}

const greenStyle = {
    color: "green"
}

const redStyle = {
    color: "red"
}