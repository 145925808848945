import {Button} from "@blueprintjs/core";
import React, {useState} from "react";
import {CreateEditDepotEntry} from "./CreateEditDepotEntry";

export function AddManualDepotEntry({reloadDepot}) {
    const [showCreate, setShowCreate] = useState(false);

    return (
        <>
            <Button text="Neuer Eintrag" icon="add" onClick={() => setShowCreate(true)}/>
            <CreateEditDepotEntry
                showDialog={showCreate}
                onCancel={() => setShowCreate(false)}
                onSave={(depotEntry) => {
                    setShowCreate(false);
                    fetch("/api/depot", {
                        method: "PUT",
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify(depotEntry)
                    }).finally(() => reloadDepot())
                }}
            />
        </>
    )
}