import {HTMLSelect} from "@blueprintjs/core";
import React from "react";

export function MarketSelect({name, register}) {
    const market = register(name);

    return <HTMLSelect
        ref={market.ref}
        name={market.name}
        onChange={market.onChange}
        onBlur={market.onBlur}
        options={[
            {value: "SIX", label: "SIX - SIX Swiss Exchange"},
            {value: "NMS", label: "NMS - Nasdaq"},
            {value: "NYS", label: "NYS - New York Stock Exchange"},
            {value: "CPH", label: "CPH - OMX Nordic Exchange Copenhagen"},
            {value: "BWB", label: "BWB - Börse Stuttgart"},
            {value: "FRA", label: "FRA - Frankfurter Wertpapierbörse"},
            {value: "ETR", label: "ETR - XETRA"},
            {value: "LSE", label: "LSE - London Stock Exchange"},
            {value: "EPA", label: "EPA - Euronext Paris"},
            {value: "OSL", label: "OSL - Oslo Stock Exchange"},
            {value: "STO", label: "STO - OMX Nordic Exchange Stockholm"},
            {value: "MCI", label: "MCI - Mercato Continuo Italiano"},
            {value: "CSE", label: "CSE - Canadian Securities Exchange"},
            {value: "TSXV", label: "TSXV - TSX Venture Exchange"},
                {value: "BCXE", label: "Cboe UK Equities - CXE"},
            {value: "FXR", label: "FXR - Forex"},
            {value: "OTC", label: "OTC - Over The Counter"},
        ]}
    />
}