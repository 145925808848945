import React, {useRef} from "react";
import {useForm} from "react-hook-form";
import {Button, ButtonGroup, Classes, Dialog, FormGroup, InputGroup, Intent} from "@blueprintjs/core";
import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {MarketSelect} from "../basic/MarketSelect";

export function CreateEditWatchlistStock({showDialog, onCancel, onSave, item}) {
    return (
        <Dialog
            title="Aktie"
            enforceFocus
            isOpen={showDialog}
            onClose={onCancel}
        >
            <Form
                onCancel={onCancel}
                onSave={onSave}
                item={item}
            />
        </Dialog>
    );
}


const schema = yup.object().shape({
    symbol: yup.string().required(),
    market: yup.string().required()
});

function Form({onCancel, onSave, item}) {
    const {register, setValue, handleSubmit, formState: {errors}} = useForm({
        defaultValues: item ?? {},
        resolver: yupResolver(schema),
    });
    const submitButton = useRef(null);

    const symbol = register("symbol");
    const name = register("name");
    const isin = register("isin");
    const currency = register("currency");
    const comment = register("comment");

    return (<>
        {/*<div className={Classes.DIALOG_HEADER}>*/}
        {/*    <FormGroup label="Suche" inline>*/}
        {/*        <StockSearch*/}
        {/*            onSelect={item => {*/}
        {/*                setValue("symbol", item.symbol);*/}
        {/*                setValue("name", item.name);*/}
        {/*                setValue("market", item.market);*/}
        {/*                setValue("currency", item.currency);*/}
        {/*                submitButton.current?.focus();*/}
        {/*            }}*/}
        {/*        />*/}
        {/*    </FormGroup>*/}
        {/*</div>*/}
        <form onSubmit={handleSubmit(onSave)}>
            <div className={Classes.DIALOG_BODY}>
                <FormGroup
                    label="Aktie (Symbol)"
                    helperText={errors.symbol?.message}
                    intent={errors.symbol ? Intent.DANGER : Intent.NONE}
                >
                    <InputGroup
                        intent={errors.symbol ? Intent.DANGER : Intent.NONE}
                        inputRef={symbol.ref}
                        name={symbol.name}
                        onChange={symbol.onChange}
                        onBlur={symbol.onBlur}
                    />
                </FormGroup>
                <FormGroup label="Name">
                    <InputGroup
                        inputRef={name.ref}
                        name={name.name}
                        onChange={name.onChange}
                        onBlur={name.onBlur}
                    />
                </FormGroup>
                <FormGroup
                    label="Börse"
                    helperText={errors.market?.message}
                    intent={errors.market ? Intent.DANGER : Intent.NONE}
                >
                    <MarketSelect name="market" register={register}/>
                </FormGroup>
                <FormGroup label="ISIN">
                    <InputGroup
                        inputRef={isin.ref}
                        name={isin.name}
                        onChange={isin.onChange}
                        onBlur={isin.onBlur}
                    />
                </FormGroup>
                <FormGroup label="Währung">
                    <InputGroup
                        inputRef={currency.ref}
                        name={currency.name}
                        onChange={currency.onChange}
                        onBlur={currency.onBlur}
                    />
                </FormGroup>
                <FormGroup label="Kommentar">
                    <InputGroup
                        inputRef={comment.ref}
                        name={comment.name}
                        onChange={comment.onChange}
                        onBlur={comment.onBlur}
                    />
                </FormGroup>
            </div>
            <div className={Classes.DIALOG_FOOTER}>
                <ButtonGroup>
                    <Button text="Speichern" intent="primary" type="submit" elementRef={submitButton}/>
                    <Button text="Abbrechen" onClick={onCancel}/>
                </ButtonGroup>
            </div>
        </form>
    </>);
}