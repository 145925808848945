import React from "react";

import {Card, HTMLTable} from "@blueprintjs/core";
import {Money} from "../basic/Money";
import {BigNumber} from "bignumber.js";
import {useFetch} from "../fetch/useFetch";

export function BudgetCategorization() {
    const {data: transactions, reload} = useFetch("/api/budget/transactions?category=unknown");

    return <>
        <h3>Zu kategorisieren</h3>
        <Card style={{padding: 0}} elevation={1}>
            <HTMLTable striped bordered compact>
                <thead>
                <tr>
                    <th>Datum</th>
                    <th>Betrag</th>
                    <th>Kategorie</th>
                    <th>description</th>
                </tr>
                </thead>
                <tbody>
                {transactions
                    .filter(t => t.category === "unknown")
                    .map((t, idx) => {
                        return <tr key={idx}>
                            <td>{t.date}</td>
                            <td style={{textAlign: "right"}}>
                                <Money value={new BigNumber(t.amount)}/>
                            </td>
                            <td>{t.category}</td>
                            <td>{t.description}</td>
                        </tr>
                    })}

                </tbody>
            </HTMLTable>
        </Card>

    </>
}