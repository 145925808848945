import React, {useEffect} from "react";
import {Classes, Dialog} from "@blueprintjs/core";
import {Spinner} from "../basic/Spinner";
import googleSignIn from "./btn_google_signin_dark_normal_web@2x.png"
import googleSignInFocus from "./btn_google_signin_dark_focus_web@2x.png"
import googleSignInPressed from "./btn_google_signin_dark_pressed_web@2x.png"
import {useLoginContext} from "../LoginContext";
import {setUser} from "@sentry/react";

export function Login({children}) {
    const {loggedIn, setLoggedIn} = useLoginContext();

    useEffect(() => {
        fetch("/api/login")
            .then(async result => {
                if (result.status === 200) {
                    let userId = await result.text();
                    setUser({id: userId});
                    console.log(`Setting user: ${userId}`)
                    setLoggedIn(true);
                } else {
                    setUser(null)
                    setLoggedIn(false);
                }
            })
    }, [])

    return (
        <>
            {loggedIn === undefined && <Spinner loading={true}/>}
            {loggedIn === false &&
            <Dialog
                title="Login"
                isOpen={!loggedIn}
                isCloseButtonShown={false}
            >
                <div className={Classes.DIALOG_BODY} style={{textAlign: "center"}}>
                    <a href="/api/login-google">
                        <input
                            type="image"
                            src={googleSignIn}
                            onMouseOver={e => e.currentTarget.src = googleSignInFocus}
                            onMouseOut={e => e.currentTarget.src = googleSignIn}
                            onMouseDown={e => e.currentTarget.src = googleSignInPressed}
                            alt="Sign in with Google"
                        />
                    </a>
                </div>
            </Dialog>}
            {loggedIn &&
            <>{children}</>}
        </>
    );
}