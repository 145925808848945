import React, {useEffect, useState} from "react";
import {Button, Spinner} from "@blueprintjs/core";

export function DepotRefresh({reloadDepot}) {
    const [refreshRunning, setRefreshRunning] = useState(false);
    const timeoutRef = React.useRef();

    useEffect(() => {
        const checkFinished = () => {
            fetch("/api/depot/refresh/status")
                .then(response => response.json())
                .then((result) => {
                    if (!result.running) {
                        setRefreshRunning(false);
                        reloadDepot();
                    }
                });
        };

        if (refreshRunning) {
            timeoutRef.current = setInterval(checkFinished, 5000);
        } else {
            clearTimeout(timeoutRef.current);
        }

        return () => clearTimeout(timeoutRef.current);
    }, [refreshRunning])


    return <Button
        icon={refreshRunning ? <Spinner size={18}/> : "refresh"}
        disabled={refreshRunning}
        onClick={() => {
            fetch("/api/depot/refresh", {method: "POST"})
                .then(() => {
                    setRefreshRunning(true);
                });
        }}
    >
        Alle Kurse updaten
    </Button>
}