import React, {useCallback, useContext, useMemo, useState} from "react";

export const LoginContext = React.createContext({
    setLoggedIn: () => {
    },
    loggedIn: undefined,
})

export function useLoginContext() {
    return useContext(LoginContext)
}

export function LoginContextProvider({children}) {
    const [state, setState] = useState({
        loggedIn: undefined
    })

    const setLoggedIn = useCallback(loggedIn => {
        setState(prev => ({
            ...prev,
            loggedIn: loggedIn
        }))
    }, [setState])

    const memoizedState = useMemo(() => ({
        setLoggedIn: setLoggedIn,
        ...state
    }), [state, setLoggedIn])

    return (
        <LoginContext.Provider value={memoizedState}>
            {children}
        </LoginContext.Provider>
    )
}