import React, {useEffect, useMemo, useState} from "react";
import {
    Bar,
    CartesianGrid,
    ComposedChart,
    Legend,
    Line,
    ReferenceArea,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis
} from "recharts";
import {Button, ButtonGroup, Radio, RadioGroup} from "@blueprintjs/core";
import {Grid} from "../basic/Grid";
import {MacdChart} from "./MacdChart";

export function StockChart({lines}) {
    let [range, setRange] = useState({
        refAreaLeft: undefined,
        refAreaRight: undefined,
        left: undefined,
        right: undefined
    });
    let [mode, setMode] = useState("percentage");

    let mappedLines = useMemo(() => {
        return lines.map(data => {
            let history = data.history.map(h => ({...h, date: Date.parse(h.date)}));
            let xMin = 999999999999999999999;
            let xMax = 0;
            let volumeMax = 0;
            history.forEach(h => {
                if (h.date < xMin) {
                    xMin = h.date;
                }
                if (h.date > xMax) {
                    xMax = h.date;
                }
                if (Number(h.volume) > volumeMax) {
                    volumeMax = h.volume;
                }
            });

            let yMax = data && data.history
                .map(n => n.close)
                .reduce((max, next) => Math.max(max, next), 0);

            return {
                id: data.symbol,
                history,
                xMin,
                xMax,
                yMax,
                volumeMax,
            }
        });
    }, [lines])

    let percentage = useMemo(() => {
        return mappedLines.map(data => {
            let filteredHistory = data.history.filter(h => h.date >= (range.left ?? 0));
            let zeroPercentAmount = filteredHistory.length > 0 ? filteredHistory[0].close : 0;

            let history = filteredHistory
                .map(h => {
                    let gainPercentage = Math.round((h.close - zeroPercentAmount) / zeroPercentAmount * 100);
                    return ({
                        date: h.date,
                        gainPercentage: gainPercentage
                    });
                });

            return {
                id: data.id,
                history: history
            };
        })
    }, [mappedLines, range.left]);


    let dataLimits = {
        xMax: mappedLines.map(l => l.xMax).reduce(Math.max),
        xMin: mappedLines.map(l => l.xMin).reduce(Math.min),
        yMax: mappedLines.map(l => l.yMax).reduce(Math.max),
    };

    useEffect(() => {
        setRange(r => ({
            ...r,
            left: lastXMonths(12, dataLimits.xMax),
            right: dataLimits.xMax,
        }));
    }, [mappedLines]);

    // Hack to immediately re-render graph so the legend is correctly placed.
    const [loaded, setLoaded] = useState(false);
    useEffect(() => {
        setTimeout(() => {
            setLoaded(true)
        }, 0)
    }, []);

    return (
        <div style={{maxWidth: "500px"}}>
            <div>
                <ResponsiveContainer
                    width="100%"
                    height={300}
                >
                    <ComposedChart
                        onMouseDown={e => setRange(p => ({...p, refAreaLeft: e?.activeLabel}))}
                        onMouseMove={e => range.refAreaLeft && setRange(p => ({
                            ...p,
                            refAreaRight: e?.activeLabel
                        }))}
                        onMouseUp={e => setRange(p => ({
                            refAreaLeft: undefined,
                            refAreaRight: undefined,
                            left: p.refAreaLeft,
                            right: p.refAreaRight
                        }))}
                        data={mappedLines[0].history}
                    >
                        <XAxis
                            dataKey="date"
                            domain={[range.left ?? dataLimits.xMin, range.right ?? dataLimits.xMax]}
                            type={"number"}
                            allowDataOverflow
                            tickFormatter={timeFormatter}
                            tickCount={20}
                            allowDuplicatedCategory={false}
                        />
                        <CartesianGrid
                            strokeDasharray="1 1"
                        />

                        {mode === "absolute" && [
                            <YAxis
                                key="xaxis1"
                                type="number"
                                domain={[0, dataLimits.yMax * 1.1]}
                                tickCount={20}
                                tickFormatter={roundingFormatter}
                            />,
                            ...mappedLines.map((data, index) => <Line
                                key={data.id}
                                name={data.id}
                                data={data.history}
                                dataKey="close"
                                dot={false}
                                stroke={COLORS[index]}
                                strokeWidth={2}
                                type="linear"
                                isAnimationActive={false}
                            />),
                        ]}

                        {mode === "percentage" && [
                            <YAxis
                                key="xaxis2"
                                type="number"
                                tickCount={20}
                                tickFormatter={value => value + "%"}
                                domain={["dataMin-5", "dataMax+5"]}
                            />,
                            ...percentage.map((data, index) => <Line
                                key={data.id}
                                name={data.id}
                                data={data.history}
                                dataKey="gainPercentage"
                                dot={false}
                                stroke={COLORS[index]}
                                strokeWidth={2}
                                type="linear"
                                isAnimationActive={false}
                            />)
                        ]}

                        {lines.length === 1 && [
                            <YAxis
                                orientation="right"
                                key="yaxisVolume"
                                type="number"
                                yAxisId="2"
                                domain={[0, mappedLines[0].volumeMax * 5]}
                                tick={false}
                                mirror
                            />,
                            <Bar
                                key="volumeBars"
                                name="Volumen"
                                dataKey="volume"
                                yAxisId="2"
                                fill="green"
                                isAnimationActive={false}
                            />
                        ]}

                        <Tooltip
                            labelFormatter={timeFormatter}
                            formatter={value => mode === "absolute" ? `${Math.round(value)} ${lines[0].info?.currency ?? ""}` : value + "%"}
                            position={{x: 70, y: 0}}
                        />

                        {lines.length > 1 &&
                            <Legend/>}

                        {range.refAreaLeft && range.refAreaRight &&
                            <ReferenceArea
                                x1={range.refAreaLeft}
                                x2={range.refAreaRight}
                                strokeOpacity={0.3}
                            />}
                    </ComposedChart>
                </ResponsiveContainer>
            </div>
            <div>
                {lines.length === 1 && lines[0].macd?.length > 0 &&
                    <MacdChart
                        stock={lines[0]}
                        left={range.left ?? dataLimits.xMin}
                        right={range.right ?? dataLimits.xMax}
                    />}
            </div>
            <Grid columns="auto auto 1fr" gap="20px">
                <ButtonGroup>
                    <Button
                        text="1M"
                        onClick={() => setRange(r => ({
                            ...r,
                            left: lastXMonths(1, dataLimits.xMax),
                            right: dataLimits.xMax,
                        }))}
                    />
                    <Button
                        text="6M"
                        onClick={() => setRange(r => ({
                            ...r,
                            left: lastXMonths(6, dataLimits.xMax),
                            right: dataLimits.xMax,
                        }))}
                    />
                    <Button
                        text="1Y"
                        onClick={() => setRange(r => ({
                            ...r,
                            left: lastXMonths(12, dataLimits.xMax),
                            right: dataLimits.xMax,
                        }))}
                    />
                    <Button
                        text="3Y"
                        onClick={() => setRange(r => ({
                            ...r,
                            left: lastXMonths(12 * 3, dataLimits.xMax),
                            right: dataLimits.xMax,
                        }))}
                    />
                    <Button text="Reset Zoom" onClick={() => setRange({})}/>
                </ButtonGroup>
                <RadioGroup
                    onChange={event => setMode(event.currentTarget.value)}
                    selectedValue={mode}
                    inline
                >
                    <Radio label="Prozent" value="percentage"/>
                    <Radio label="Absolut" value="absolute"/>
                </RadioGroup>
            </Grid>
        </div>
    )
}

const COLORS = ["#2965CC", "#29A634", "#D99E0B", "#D13913", "#8F398F", "#00B3A4", "#DB2C6F", "#9BBF30", "#96622D", "#7157D9"];

function lastXMonths(months, xMax) {
    let date = xMax ? new Date(xMax) : new Date();
    date.setMonth(date.getMonth() - months);
    return date.getTime();
}

function timeFormatter(milliseconds) {
    return new Date(milliseconds).toLocaleDateString("de-CH");
}

function roundingFormatter(value) {
    return Math.round(value);
}