import React, {useMemo} from "react";
import {useFetch} from "../fetch/useFetch";
import {Table} from "../basic/Table";
import {MoneyCellRenderer} from "./MoneyCellRenderer";
import {DateTime} from "../basic/DateTime";

export function TransactionTable({symbol}) {
    const {data: transactions} = useFetch(`/api/depot/${symbol}/transactions`);

    let columns = useMemo(() => [
        {
            Header: "Datum",
            accessor: "date",
            Cell: DateTime
        },
        {
            Header: "Aktion",
            accessor: "type",
        },
        {
            Header: "Menge",
            accessor: "quantity",
            Cell: MoneyCellRenderer,
            width: "100px"
        },
        {
            Header: "Preis",
            accessor: "unitcost",
            Cell: MoneyCellRenderer,
            width: "100px"
        },
        {
            Header: "Währung",
            accessor: "currency",
            width: "100px"
        },
        {
            Header: "Gebühren",
            accessor: "fees",
            Cell: MoneyCellRenderer,
            width: "100px"
        }
    ], []);

    return (
        <div style={{width: "100%"}}>
            <Table
                data={transactions}
                columns={columns}
                updateSelectedRows={() => {
                }}
                style={{width: "100%"}}
            />
        </div>
    );
}