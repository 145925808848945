import React, {useMemo} from "react";
import {CartesianGrid, LabelList, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";

export function MacdChart({stock, left, right}) {

    let chartData = useMemo(() => {
        let min = 0;
        let max = 0;
        let history = stock.macd
            .map(h => {
                let macd = Number(h.macd);
                let macdSignal = Number(h.macd_signal);
                if (macd < min) {
                    min = macd;
                }
                if (macd > max) {
                    max = macd;
                }
                return ({
                    ...h,
                    date: Date.parse(h.date),
                    macd: macd,
                    macd_signal: macdSignal,
                    buy: false,
                    sell: false
                });
            })
            .filter(h => h.date >= left && (right === undefined || h.date <= right));

        const scale = Math.abs(max - min);
        const trigger = scale / 100;

        for (let i = 1; i < history.length; i++) {
            const a = history[i - 1];
            const b = history[i];

            // Should match backend algorithm in server/indicator_notification.py
            if (a.macd > a.macd_signal && b.macd < b.macd_signal && b.macd > 2) {
                history[i].sell = true;
            }
            if (a.macd < a.macd_signal && b.macd > b.macd_signal && b.macd < -2) {
                history[i].buy = true;
            }
        }

        let yMax = Math.ceil(history
            .map(n => n.macd)
            .reduce((max, next) => Math.max(max, next), -1000));
        let yMin = Math.floor(history
            .map(n => n.macd)
            .reduce((max, next) => Math.min(max, next), 1000));

        return {
            id: stock.symbol.substring(7),
            macd: history,
            yMax,
            yMin,
        }
    }, [stock, left, right]);

    return (
        <div>
            <div>
                <ResponsiveContainer
                    width="100%"
                    height={150}
                >
                    <LineChart>
                        <Legend/>
                        <XAxis
                            dataKey="date"
                            domain={[left, right]}
                            type={"number"}
                            allowDataOverflow
                            tickFormatter={timeFormatter}
                            tickCount={20}
                            allowDuplicatedCategory={false}
                        />
                        <CartesianGrid
                            strokeDasharray="1 1"
                        />
                        <YAxis
                            key="yaxis1"
                            type="number"
                            domain={[chartData.yMin * 1.1, chartData.yMax * 1.1]}
                            tickCount={20}
                            tickFormatter={roundingFormatter}
                        />,
                        <Line
                            key="macd"
                            name="MACD"
                            data={chartData.macd}
                            dataKey="macd"
                            stroke={COLORS[0]}
                            strokeWidth={2}
                            type="linear"
                            dot={<CustomDot/>}
                            isAnimationActive={false}
                        >
                            <LabelList
                                valueAccessor={v => {
                                    if (v.payload.date < left) {
                                        return null;
                                    }

                                    if (v.payload.buy) {
                                        return "Buy";
                                    } else if (v.payload.sell) {
                                        return "Sell";
                                    } else {
                                        return null;
                                    }
                                }}
                                position="top"
                                offset={10}
                            />
                        </Line>
                        <Line
                            key="macd_signal"
                            name="MACD Signal"
                            data={chartData.macd}
                            dataKey="macd_signal"
                            dot={false}
                            stroke={COLORS[2]}
                            strokeWidth={2}
                            type="linear"
                            isAnimationActive={false}
                        />

                        <Tooltip
                            labelFormatter={timeFormatter}
                            formatter={value => value}
                            position={{x: 70, y: -320}}
                        />
                    </LineChart>
                </ResponsiveContainer>

            </div>
        </div>
    )
}

const COLORS = ["#2965CC", "#29A634", "#D99E0B", "#D13913", "#8F398F", "#00B3A4", "#DB2C6F", "#9BBF30", "#96622D", "#7157D9"];


function timeFormatter(milliseconds) {
    return new Date(milliseconds).toLocaleDateString("de-CH");
}

function roundingFormatter(value) {
    return Math.round(value);
}

function CustomDot(props) {
    const {cx, cy, payload} = props;

    if (payload.buy || payload.sell) {
        return (
            <circle cx={cx} cy={cy} r={4} fill={payload.buy ? "green" : "red"}/>
        )
    } else {
        return null;
    }

}