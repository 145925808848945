import React from "react";
import {useFetch} from "../fetch/useFetch";
import {Card, HTMLTable} from "@blueprintjs/core";
import {Money} from "../basic/Money";
import {BigNumber} from "bignumber.js";
import {NavLink} from "react-router-dom";

export function BudgetPage() {
    const {data: transactions, reload} = useFetch("/api/budget/transactions");

    let years = new Set();
    let categories = new Set();
    let sums = new Map();
    transactions.forEach(t => {
        let date = new Date(t.date);
        let year = date.getFullYear();
        years.add(year);
        categories.add(t.category);
        let key = t.category + "-" + year;
        let currentSum = sums.get(key) ?? new BigNumber(0);
        currentSum = currentSum.plus(new BigNumber(t.amount));
        sums.set(key, currentSum);
    });
    years = Array.from(years).sort((a, b) => a >= b);

    return (
        <>
            <h1>Budget</h1>
            <h3>Groups</h3>
            <div style={{display: "inline-block"}}>
                <Card style={{padding: 0}} elevation={1}>
                    <HTMLTable striped bordered compact>
                        <thead>
                        <tr>
                            <th key={"cat"}>Kategorie</th>
                            {years.map(y => <th key={y}><NavLink to={`/budget/year/${y}`}>{y.toString()}</NavLink>
                            </th>)}
                        </tr>
                        </thead>
                        <tbody>
                        {Array.from(categories.values()).map(cat =>
                            <tr key={cat}>
                                <td>{cat}</td>
                                {years.map(y =>
                                    <td key={y}>
                                        <Money value={sums.get(cat + "-" + y)} places={0}/>
                                    </td>
                                )}
                            </tr>
                        )}
                        </tbody>
                    </HTMLTable>
                </Card>
            </div>
        </>
    )
}