import React from "react";
import {Button, H1} from "@blueprintjs/core";

export function ErrorFallback({error, componentStack, resetError}) {
    return (
        <>
            <H1>Interner Fehler!</H1>
            <Button
                onClick={() => {
                    resetError();
                }}
            >
                Zurücksetzen
            </Button>
            <pre>{error.toString()}
                {componentStack}
            </pre>
        </>
    )
}