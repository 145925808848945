import React from "react";
import {Spinner as BpSpinner} from "@blueprintjs/core";

export function Spinner({loading, children}) {
    return (
        <>
            {loading && <div style={{padding: "20px"}}>
                <BpSpinner/>
            </div>}
            {!loading && children}
        </>
    );
}