import React from "react"
import {useParams} from "react-router-dom";
import {StockDetail} from "./StockDetail";

export function StockDetailPage() {
    let {symbol, market} = useParams();

    return (
        <StockDetail symbol={symbol} market={market}/>
    )
}