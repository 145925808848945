import React from "react";
import {Button} from "@blueprintjs/core";

export class EditButtonCellRenderer extends React.Component {
    render() {
        return (
            <Button minimal icon="edit" onClick={() => this.props.setEditingId(this.props.value)}/>
        )
    }

}