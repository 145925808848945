import React, {useEffect} from "react";
import {useExpanded, useRowSelect, useTable} from "react-table";
import {HTMLTable} from "@blueprintjs/core";

export function Table({columns, data, updateSelectedRows, style}) {
    let {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        selectedFlatRows,
        toggleAllRowsExpanded,
        visibleColumns
    } = useTable({
            columns,
            data,
            autoResetExpanded: false,
        },
        useExpanded,
        useRowSelect
    );

    useEffect(() => {
        toggleAllRowsExpanded(true);
    }, [data]);

    useEffect(() => updateSelectedRows(selectedFlatRows
            .map(r => r.original)
            .filter(r => "_id" in r)),
        [selectedFlatRows]);

    return (
        <HTMLTable
            striped
            compact
            bordered
            {...getTableProps()}
            style={{tableLayout: "fixed", ...style}}
        >
            <thead>
            {headerGroups.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map(column => (
                        <th {...column.getHeaderProps()} style={{
                            width: column.width,
                            overflow: "hidden",
                            wordWrap: "break-word"
                        }}
                        >
                            {column.render('Header')}
                        </th>
                    ))}
                </tr>
            ))}
            </thead>
            <tbody {...getTableBodyProps()}>
            {rows.map((row, i) => {
                prepareRow(row)
                return (
                    <tr {...row.getRowProps()}>
                        {row.cells.map(cell => {
                            return (
                                <td {...cell.getCellProps()}
                                    style={{
                                        width: cell.column.width,
                                        overflow: "hidden",
                                        wordWrap: "break-word",
                                        fontWeight: row.canExpand ? "600" : undefined,
                                    }}
                                >
                                    {cell.render('Cell')}
                                </td>
                            )
                        })}
                    </tr>
                )
            })}
            </tbody>
        </HTMLTable>
    );
}