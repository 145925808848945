import {Money} from "../basic/Money";
import React from "react";
import {BigNumber} from "bignumber.js";

export function MoneyCellRenderer({value}) {
    return <div style={{textAlign: "right"}}>
        {value && <Money value={value instanceof BigNumber ? value : new BigNumber(value)}/>}
    </div>;
}

export function DecimalMoneyCellRenderer({value}) {
    let places = Math.min(value.decimalPlaces(), 5);
    return <div style={{textAlign: "right"}}>
        {value && <Money value={value instanceof BigNumber ? value : new BigNumber(value)} places={places}/>}
    </div>;
}