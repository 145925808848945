import React from "react";
import {Checkbox, MenuDivider} from "@blueprintjs/core";
import {intersection} from "lodash";

export class LabelFilter extends React.Component {
    constructor(props) {
        super(props);
        const groups = this.calculateRows();
        this.state = {
            selectedGroups: [],
            groups: [...groups],
        }
    }

    calculateRows() {
        const colId = this.props.column.colId;
        const groups = new Set();
        this.props.api.forEachNode(node => {
            let groupsOfNode = node.data[colId];
            groupsOfNode.forEach(g => groups.add(g))
        });
        return Array.from(groups.values());
    }

    getModelAsString() {
        return this.state.selectedGroups.join(", ");
    }

    getModel() {
        return {value: this.state.selectedGroups};
    }

    setModel(model) {
        console.log("LabelFilter set model", model);
    }

    isFilterActive() {
        return true;
    }

    doesFilterPass(params) {
        if (this.state.selectedGroups.length === 0) {
            return true;
        }
        let colId = this.props.column.colId;
        let result = intersection(params.data[colId], this.state.selectedGroups);
        return result.length > 0;
    }

    onNewRowsLoaded() {
        this.setState(prev => {
            return {
                ...prev,
                groups: this.calculateRows(),
            }
        });
    }

    render() {
        return (
            <div style={{padding: "10px"}}>
                <Checkbox
                    checked={this.state.selectedGroups.length === this.state.groups.length}
                    onChange={e => {
                        let checked = e.currentTarget.checked;
                        this.setState(prev => {
                                if (checked) {
                                    prev.selectedGroups = [...this.state.groups];
                                } else {
                                    prev.selectedGroups = [];
                                }
                                return prev;
                            },
                            () => this.props.filterChangedCallback()
                        );
                    }}
                >
                    Alle
                </Checkbox>
                <MenuDivider/>
                {this.state.groups.map(l =>
                    <Checkbox
                        key={l}
                        checked={this.state.selectedGroups.indexOf(l) >= 0}
                        onChange={e => {
                            let checked = e.currentTarget.checked;
                            this.setState(prev => {
                                    if (checked) {
                                        prev.selectedGroups.push(l);
                                    } else {
                                        let index = prev.selectedGroups.indexOf(l);
                                        prev.selectedGroups.splice(index, 1);
                                    }
                                    return prev;
                                },
                                () => this.props.filterChangedCallback()
                            );
                        }}
                    >
                        {l}
                    </Checkbox>)}
            </div>
        )
    }
}