import React, {useContext, useEffect, useState} from "react";
import {useFetch} from "./fetch/useFetch";

export const GlobalContext = React.createContext({
})

export function useGlobalContext() {
    return useContext(GlobalContext)
}

export function GlobalContextProvider({children}) {
    const [state, setState] = useState({
        userSettings: {data: [], loading: true},
        "table-watchlist": {data: [], loading: true},
        "table-depot": {data: [], loading: true},
        "table-cash": {data: [], loading: true},
        "table-structured_products": {data: [], loading: true},
        depot: {data: [], loading: true},
        currency: {data: [], loading: true},
        watchlist: {data: [], loading: true},
    })

    loadIntoState(setState, "userSettings", "/api/user/settings");
    loadIntoState(setState, "table-watchlist", `/api/user/tables/watchlist`);
    loadIntoState(setState, "table-depot", `/api/user/tables/depot`);
    loadIntoState(setState, "table-cash", `/api/user/tables/cash`);
    loadIntoState(setState, "table-structured_products", `/api/user/tables/structured_products`);

    loadIntoState(setState, "depot", "/api/depot");
    loadIntoState(setState, "currency", "/api/currency");
    loadIntoState(setState, "watchlist", "/api/watchlist");

    return <GlobalContext.Provider value={state}>
        {children}
    </GlobalContext.Provider>
}

function loadIntoState(setState, fieldName, url, initial = []) {
    const fetched = useFetch(url, initial);
    useEffect(() => {
        setState(state => ({
            ...state,
            [fieldName]: fetched
        }))
    }, [fetched.data, fetched.error, fetched.loading]);
}
