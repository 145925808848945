import React from "react";
import {Position, Tooltip} from "@blueprintjs/core";
import {DateTime} from "../../DateTime";

export class MacdCellRenderer extends React.Component {
    render() {
        let value = this.props.value;
        return (
            <div style={{textAlign: "center"}}>
                {value &&
                <Tooltip
                    content={<>
                        <div>Update vom <DateTime value={value.date} dateOnly/></div>
                        <div>MACD {value.macd}</div>
                        <div>MACD Signal {value.macd_signal}</div>
                        <div>MACD Hist {value.macd_hist}</div>
                    </>}
                    position={Position.RIGHT}
                >
                    <>
                        {Number(value.macd) > Number(value.macd_signal) &&
                        <div style={greenStyle}>{Number(value.macd).toFixed(1)}</div>}
                        {Number(value.macd) < Number(value.macd_signal) &&
                        <div style={redStyle}>{Number(value.macd).toFixed(1)}</div>}
                    </>
                </Tooltip>}
            </div>
        )
    }
}

const greenStyle = {
    color: "green",
};

const redStyle = {
    color: "red",
};