import {useForm} from "react-hook-form";
import {Button, Checkbox, Classes, ControlGroup, Divider, FormGroup, InputGroup, Popover} from "@blueprintjs/core";
import {uniq, without} from "lodash";
import React from "react";

export function LabelUpdater({groups, selectedRows, list, update}) {
    const {handleSubmit, register} = useForm();
    const selectedListEntries = list.filter(l => selectedRows.indexOf(l._id) >= 0);

    const newLabel = register("newLabel");

    return (
        <Popover
            popoverClassName={Classes.POPOVER_CONTENT_SIZING}
            content={
                <>
                    {selectedRows.length === 0 &&
                        <>Keine Zeilen ausgewählt</>}
                    {selectedRows.length > 0 &&
                        <>
                            <form
                                onSubmit={handleSubmit(data => {
                                    let updates = selectedListEntries.map(stock => {
                                        return {
                                            _id: stock._id,
                                            groups: uniq([...(stock.groups ?? []), data.newLabel]),
                                        };
                                    });
                                    update(updates);
                                })}
                            >
                                <FormGroup inline label="Neues Label">
                                    <ControlGroup fill={true}>
                                        <InputGroup
                                            name={newLabel.name}
                                            inputRef={newLabel.ref}
                                            onChange={newLabel.onChange}
                                            onBlur={newLabel.onBlur}
                                        />
                                        <Button icon="add" type="submit"/>
                                    </ControlGroup>
                                </FormGroup>
                            </form>
                            <Divider/>
                            {groups.map(l => {
                                let result = isChecked(l, selectedListEntries);
                                return (
                                    <Checkbox
                                        key={l}
                                        checked={result === "checked"}
                                        indeterminate={result === "indeterminate"}
                                        onChange={e => {
                                            const checked = e.currentTarget.checked;
                                            const updates = selectedListEntries.map(stock => {
                                                if (checked) {
                                                    stock.groups = uniq([...(stock.groups ?? []), l]);
                                                } else {
                                                    stock.groups = without(stock.groups ?? [], l);
                                                }

                                                return {
                                                    _id: stock._id,
                                                    groups: stock.groups,
                                                }
                                            })
                                            update(updates);
                                        }}
                                    >
                                        {l}
                                    </Checkbox>
                                );
                            })}
                        </>}
                </>
            }
        >
            <Button text="Labels" icon="tag"/>
        </Popover>
    );
}


function isChecked(label, selectedListEntries) {
    if (selectedListEntries.length === 0) {
        return "unchecked";
    }
    let filtered = selectedListEntries.filter(e => e.groups?.indexOf(label) >= 0);
    if (filtered.length === selectedListEntries.length) {
        return "checked";
    } else if (filtered.length > 0) {
        return "indeterminate";
    } else {
        return "unchecked";
    }
}