import React from "react";
import {Position, Tooltip} from "@blueprintjs/core";
import {Money} from "../../Money";
import {BigNumber} from "bignumber.js";
import {DateTime} from "../../DateTime";

export class PriceCellRenderer extends React.Component {
    render() {
        return (
            <>
                {this.props.value && this.props.value.market !== "FXR" &&
                <Tooltip
                    content={<>
                        <div>Update vom <DateTime value={this.props.value.date} dateOnly/></div>
                        <div>Open: <Money value={new BigNumber(this.props.value.open)} places={2}/></div>
                        <div>High: <Money value={new BigNumber(this.props.value.high)} places={2}/></div>
                        <div>Low: <Money value={new BigNumber(this.props.value.low)} places={2}/></div>
                        <div>Close: <Money value={new BigNumber(this.props.value.close)} places={2}/></div>
                    </>}
                    position={Position.RIGHT}
                >
                    <Money value={new BigNumber(this.props.value.close)}/>
                </Tooltip>}
                {this.props.value && this.props.value.market === "FXR" &&
                <Tooltip
                    content={<>
                        <div>Update vom <DateTime value={this.props.value.date} dateOnly/></div>
                        <div>Exchange Rate: {this.props.value.exchange_rate}</div>
                        <div>Ask: {this.props.value.ask_price}</div>
                        <div>Bid: {this.props.value.bid_price}</div>
                    </>}
                    position={Position.RIGHT}
                >
                    <Money value={new BigNumber(this.props.value.exchange_rate)}/>
                </Tooltip>}
            </>
        )
    }
}